import React, { useEffect } from 'react'
import { Typography, Form, Input, Button, message } from 'antd'
import api from '../../api'
import { useState } from 'react'

export default () => {
  const [listContact, setListContact] = useState([])
  const [newContact, setNewContact] = useState('')

  const getData = async () => {
    try {
      const { data } = await api.get('/content')
      if (data) {
        setListContact(data.data)
      }
    } catch (err) {}
  }

  const onFinish = async values => {
    try {
      await api.put('/content', { content: values.cskh })
      message.success('Thay đổi thành công')
    } catch (err) {}
  }

  useEffect(() => {
    getData()
  }, [])

  const addContact = async () => {
    try {
      await api.post('/content/new', { content: newContact })
      message.success('Thay đổi thành công')
      getData()
    } catch (err) {}
  }

  const deletedContact = async (contact) => {
    console.log(contact)
    try {
      await api.put('/content/delete', { content: contact })
      message.success('Xóa thành công')
      getData()
    } catch (err) {}
  }

  return (
    <div className="contactt">
      <Typography.Title level={5}> Chỉnh sửa đường dẫn CSKH </Typography.Title>
      <br />
      <div className="add_contact">
        <input type="text" value={newContact} onChange={e => setNewContact(e.target.value)} />
        <Button onClick={() => addContact()}>Thêm</Button>
      </div>
      <h4>List</h4>
      <ul className="list_contact">
        {listContact.map((item, index) => {
          return (
            <li key={index}>
              <input type="text" value={item.content} />
              <Button onClick={() => deletedContact(item.content)}>Delete</Button>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
